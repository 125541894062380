import moment from "moment/moment";
import {Ref} from "vue";

export interface Fields {
    inputFormType: string,
    validation: any;
    type: Ref<string>;
    label: string;
    validateOn: string;
    prefix: string;
    prependInnerIcon: string;
    tooltip: string;
    disabled: Ref<boolean>;
    items?: object;
    options?: object | TypeAccount[];
    value?: object;
    suffix?:string;
}

export interface TypeAccount {
    id: string;
    value: string;
}

export interface Step {
    id: number;
    value: string;
}

export interface Platform {
    id: string;
    value: string;
}

export interface OrderState {
    id: string;
    value: string;
}

export interface Time {
    id: string;
    value: string;
}

interface BankList {
    id: string;
    value: string;
    med: boolean;
}

export const currency = (value: number) => {
    return new Intl.NumberFormat("es-CL", {
        currency: "CLP",
        style: "currency",
    }).format(value);
};
export const time = (seconds: number) => {
    let response = "";
    const numdays = Math.floor(seconds / 86400);
    const numhours = Math.floor((seconds % 86400) / 3600);
    const numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    const numseconds = ((seconds % 86400) % 3600) % 60;
    if (numdays != 0) response += numdays + (numdays > 1 ? " Dias " : " Dia ");
    if (numhours != 0) response += numhours + (numhours > 1 ? " Horas " : " Hora ");
    if (numminutes != 0) response += numminutes + (numminutes > 1 ? " Minutos " : " Minuto ");
    if (numseconds != 0) response += numseconds + (numseconds > 1 ? " Segundos " : " Segundo ");
    return response;
};
export const date = (date: moment.MomentInput) => {
    return moment(date).format("DD/MM/YYYY hh:mm:ss A");
};

export const capitalize = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
};

export const platform = (input: string) => {
    switch (input) {
        case "paymentlink":
            return "Paymentlink";
        case "jumpseller":
            return "Jumpseller";
        case "woocommerce":
            return "WooCommerce";
        case "externalpayment":
            return "Aplicacion externa";
        case "vtex":
            return "VTEX";
        default:
            return input;
    }
};

export const filterStepOnboarding = (input: number) => {
    const step = getStepOnboarding().filter((T) => {
        return T.id === input
    })
    return step[0]?.value || '';
};

export const filterBank = (input: string) => {
    const step = getBanks().filter((T) => {
        return T.id === input
    })
    return step[0]?.value || '';
};

export const isMED = (input: string) => {
    const step = getBanks().filter((T) => {
        return T.id === input
    })
    return step[0]?.med || '';
};

export const getTypeAccount = (): TypeAccount[] => {
    return [{
        id: "001",
        value: "Cuenta Corriente",
    }, {
        id: "002",
        value: "Cuenta Vista",
    }]
}

export const getStepOnboarding = (): Step[] => {
    return [{
        id: 1,
        value: "1. Creación de cuenta usuario",
    }, {
        id: 2,
        value: "2. Datos del Comercio",
    }, {
        id: 3,
        value: "3. Cuenta Bancaria",
    }, {
        id: 4,
        value: "4. Opciones de Abono",
    }, {
        id: 5,
        value: "5. Comercio en alta Blix",
    }]
}
export const getTime = (): Time[] => {
    return [{
        id: 'minutes',
        value: "Minutos",
    }, {
        id: 'hours',
        value: "Horas",
    }, {
        id: 'days',
        value: "Días",
    }]
}

export const getPlatform = (): Platform[] => {
    return [{
        id: 'paymentlink',
        value: "Paymentlink",
    }, {
        id: 'jumpseller',
        value: "Jumpseller",
    }, {
        id: 'woocommerce',
        value: "WooCommerce",
    }, {
        id: 'externalpayment',
        value: "Aplicacion externa",
    }, {
        id: 'vtex',
        value: "VTEX",
    }]
}

export const getOrderState = (): OrderState[] => {
    return [{
        id: "Sin pago aún",
        value: "Sin pago aún",
    },{
        id: "Pagado / Agotado",
        value: "Pagado / Agotado",
    }, {
        id: "Revocado",
        value: "Revocado"
    }]
}

export const getBanks = (): BankList[] => {
    return [
        {
            id: "504",
            value: "Banco Bilbao Viscaya Argentina",
            med: false,
        },
        {
            id: "672",
            value: "Coopeuch",
            med: false,
        },
        {
            id: "693",
            value: "Promotora CMR Falabella S.A",
            med: false,
        },
        {
            id: "729",
            value: "Prepago los Heroes",
            med: true,
        },
        {
            id: "730",
            value: "Prepago Tenpo",
            med: false,
        },
        {
            id: "001",
            value: "Banco de Chile",
            med: false,
        },
        {
            id: "009",
            value: "Banco Internacional",
            med: false,
        },
        {
            id: "012",
            value: "Banco del Estado de Chile",
            med: false,
        },
        {
            id: "014",
            value: "Scotiabank Chile",
            med: false,
        },
        {
            id: "016",
            value: "Banco de créditos e inversiones",
            med: false,
        },
        {
            id: "027",
            value: "Corpbanca",
            med: false,
        },
        {
            id: "028",
            value: "Banco Bice",
            med: true,
        },
        {
            id: "037",
            value: "Banco Santander Chile",
            med: false,
        },
        {
            id: "039",
            value: "Banco Itaú Chile",
            med: false,
        },
        {
            id: "049",
            value: "Banco Security",
            med: false,
        },
        {
            id: "051",
            value: "Banco Falabella",
            med: false,
        },
        {
            id: "053",
            value: "Banco Ripley",
            med: false,
        },
        {
            id: "055",
            value: "Banco Consorcio",
            med: false,
        },
    ];
};

export const getCityState = () => {
    return [
        {
            region: "Región Metropolitana de Santiago",
            comunas: [
                "Santiago",
                "Cerrillos",
                "Cerro Navia",
                "Conchalí",
                "El Bosque",
                "Estación Central",
                "Huechuraba",
                "Independencia",
                "La Cisterna",
                "La Florida",
                "La Granja",
                "La Pintana",
                "La Reina",
                "Las Condes",
                "Lo Barnechea",
                "Lo Espejo",
                "Lo Prado",
                "Macul",
                "Maipú",
                "Ñuñoa",
                "Pedro Aguirre Cerda",
                "Peñalolén",
                "Providencia",
                "Pudahuel",
                "Quilicura",
                "Quinta Normal",
                "Recoleta",
                "Renca",
                "San Joaquín",
                "San Miguel",
                "San Ramón",
                "Vitacura",
                "Puente Alto",
                "Pirque",
                "San José de Maipo",
                "Colina",
                "Lampa",
                "Tiltil",
                "San Bernardo",
                "Buin",
                "Calera de Tango",
                "Paine",
                "Melipilla",
                "Alhué",
                "Curacaví",
                "María Pinto",
                "San Pedro",
                "Talagante",
                "El Monte",
                "Isla de Maipo",
                "Padre Hurtado",
                "Peñaflor",
            ],
        },
        {
            region: "Arica y Parinacota",
            comunas: ["Arica", "Camarones", "Putre", "General Lagos"],
        },
        {
            region: "Tarapacá",
            comunas: [
                "Iquique",
                "Alto Hospicio",
                "Pozo Almonte",
                "Camiña",
                "Colchane",
                "Huara",
                "Pica",
            ],
        },
        {
            region: "Antofagasta",
            comunas: [
                "Antofagasta",
                "Mejillones",
                "Sierra Gorda",
                "Taltal",
                "Calama",
                "Ollagüe",
                "San Pedro de Atacama",
                "Tocopilla",
                "María Elena",
            ],
        },
        {
            region: "Atacama",
            comunas: [
                "Copiapó",
                "Caldera",
                "Tierra Amarilla",
                "Chañaral",
                "Diego de Almagro",
                "Vallenar",
                "Alto del Carmen",
                "Freirina",
                "Huasco",
            ],
        },
        {
            region: "Coquimbo",
            comunas: [
                "La Serena",
                "Coquimbo",
                "Andacollo",
                "La Higuera",
                "Paiguano",
                "Vicuña",
                "Illapel",
                "Canela",
                "Los Vilos",
                "Salamanca",
                "Ovalle",
                "Combarbalá",
                "Monte Patria",
                "Punitaqui",
                "Río Hurtado",
            ],
        },
        {
            region: "Valparaíso",
            comunas: [
                "Valparaíso",
                "Casablanca",
                "Concón",
                "Juan Fernández",
                "Puchuncaví",
                "Quintero",
                "Viña del Mar",
                "Isla de Pascua",
                "Los Andes",
                "Calle Larga",
                "Rinconada",
                "San Esteban",
                "La Ligua",
                "Cabildo",
                "Papudo",
                "Petorca",
                "Zapallar",
                "Quillota",
                "Calera",
                "Hijuelas",
                "La Cruz",
                "Nogales",
                "San Antonio",
                "Algarrobo",
                "Cartagena",
                "El Quisco",
                "El Tabo",
                "Santo Domingo",
                "San Felipe",
                "Catemu",
                "Llaillay",
                "Panquehue",
                "Putaendo",
                "Santa María",
                "Quilpué",
                "Limache",
                "Olmué",
                "Villa Alemana",
            ],
        },
        {
            region: "Región del Libertador Gral. Bernardo O’Higgins",
            comunas: [
                "Rancagua",
                "Codegua",
                "Coinco",
                "Coltauco",
                "Doñihue",
                "Graneros",
                "Las Cabras",
                "Machalí",
                "Malloa",
                "Mostazal",
                "Olivar",
                "Peumo",
                "Pichidegua",
                "Quinta de Tilcoco",
                "Rengo",
                "Requínoa",
                "San Vicente",
                "Pichilemu",
                "La Estrella",
                "Litueche",
                "Marchihue",
                "Navidad",
                "Paredones",
                "San Fernando",
                "Chépica",
                "Chimbarongo",
                "Lolol",
                "Nancagua",
                "Palmilla",
                "Peralillo",
                "Placilla",
                "Pumanque",
                "Santa Cruz",
            ],
        },
        {
            region: "Región del Maule",
            comunas: [
                "Talca",
                "Constitución",
                "Curepto",
                "Empedrado",
                "Maule",
                "Pelarco",
                "Pencahue",
                "Río Claro",
                "San Clemente",
                "San Rafael",
                "Cauquenes",
                "Chanco",
                "Pelluhue",
                "Curicó",
                "Hualañé",
                "Licantén",
                "Molina",
                "Rauco",
                "Romeral",
                "Sagrada Familia",
                "Teno",
                "Vichuquén",
                "Linares",
                "Colbún",
                "Longaví",
                "Parral",
                "Retiro",
                "San Javier",
                "Villa Alegre",
                "Yerbas Buenas",
            ],
        },
        {
            region: "Región del Biobío",
            comunas: [
                "Concepción",
                "Coronel",
                "Chiguayante",
                "Florida",
                "Hualqui",
                "Lota",
                "Penco",
                "San Pedro de la Paz",
                "Santa Juana",
                "Talcahuano",
                "Tomé",
                "Hualpén",
                "Lebu",
                "Arauco",
                "Cañete",
                "Contulmo",
                "Curanilahue",
                "Los Álamos",
                "Tirúa",
                "Los Ángeles",
                "Antuco",
                "Cabrero",
                "Laja",
                "Mulchén",
                "Nacimiento",
                "Negrete",
                "Quilaco",
                "Quilleco",
                "San Rosendo",
                "Santa Bárbara",
                "Tucapel",
                "Yumbel",
                "Alto Biobío",
                "Chillán",
                "Bulnes",
                "Cobquecura",
                "Coelemu",
                "Coihueco",
                "Chillán Viejo",
                "El Carmen",
                "Ninhue",
                "Ñiquén",
                "Pemuco",
                "Pinto",
                "Portezuelo",
                "Quillón",
                "Quirihue",
                "Ránquil",
                "San Carlos",
                "San Fabián",
                "San Ignacio",
                "San Nicolás",
                "Treguaco",
                "Yungay",
            ],
        },
        {
            region: "Región de la Araucanía",
            comunas: [
                "Temuco",
                "Carahue",
                "Cunco",
                "Curarrehue",
                "Freire",
                "Galvarino",
                "Gorbea",
                "Lautaro",
                "Loncoche",
                "Melipeuco",
                "Nueva Imperial",
                "Padre las Casas",
                "Perquenco",
                "Pitrufquén",
                "Pucón",
                "Saavedra",
                "Teodoro Schmidt",
                "Toltén",
                "Vilcún",
                "Villarrica",
                "Cholchol",
                "Angol",
                "Collipulli",
                "Curacautín",
                "Ercilla",
                "Lonquimay",
                "Los Sauces",
                "Lumaco",
                "Purén",
                "Renaico",
                "Traiguén",
                "Victoria",
            ],
        },
        {
            region: "Región de Los Ríos",
            comunas: [
                "Valdivia",
                "Corral",
                "Lanco",
                "Los Lagos",
                "Máfil",
                "Mariquina",
                "Paillaco",
                "Panguipulli",
                "La Unión",
                "Futrono",
                "Lago Ranco",
                "Río Bueno",
            ],
        },
        {
            region: "Región de Los Lagos",
            comunas: [
                "Puerto Montt",
                "Calbuco",
                "Cochamó",
                "Fresia",
                "Frutillar",
                "Los Muermos",
                "Llanquihue",
                "Maullín",
                "Puerto Varas",
                "Castro",
                "Ancud",
                "Chonchi",
                "Curaco de Vélez",
                "Dalcahue",
                "Puqueldón",
                "Queilén",
                "Quellón",
                "Quemchi",
                "Quinchao",
                "Osorno",
                "Puerto Octay",
                "Purranque",
                "Puyehue",
                "Río Negro",
                "San Juan de la Costa",
                "San Pablo",
                "Chaitén",
                "Futaleufú",
                "Hualaihué",
                "Palena",
            ],
        },
        {
            region: "Región Aisén del Gral. Carlos Ibáñez del Campo",
            comunas: [
                "Coihaique",
                "Lago Verde",
                "Aisén",
                "Cisnes",
                "Guaitecas",
                "Cochrane",
                "O’Higgins",
                "Tortel",
                "Chile Chico",
                "Río Ibáñez",
            ],
        },
        {
            region: "Región de Magallanes y de la Antártica Chilena",
            comunas: [
                "Punta Arenas",
                "Laguna Blanca",
                "Río Verde",
                "San Gregorio",
                "Cabo de Hornos (Ex Navarino)",
                "Antártica",
                "Porvenir",
                "Primavera",
                "Timaukel",
                "Natales",
                "Torres del Paine",
            ],
        }
    ];
};

//----> Funcionalidad para determianr el tipo de usuario
export function isCommerce(keycloak: any): boolean {
    const enrollerUserId = keycloak.tokenParsed?.commerces;
    return !enrollerUserId;
}

export enum EnrollerModule {
    PUBLIC = 'public',
    CUENTAS_COMERCIOS = 'cuentas_comercios',
    GATEWAY_JUMPSELLER = 'gateway_jumpseller',
    GATEWAY_LINK_PAGO = 'gateway_link_pago',
    GATEWAY_PERSONALIZADO = 'gateway_personalizado',
    GATEWAY_VTEX = 'gateway_vtex',
    GATEWAY_WOOCOMMERCE = 'gateway_woocommerce',
    GESTION_API_KEY = 'gestion_api_key',
    GESTION_COMERCIOS = 'gestion_comercios',
    GESTION_PUNTOS_VENTA = 'gestion_puntos_venta',
    ONBOARDING = 'onboarding',
    ORDEN_COMPRA = 'orden_compra',
    ORDEN_COMPRA_DASHBOARD = 'orden_compra_dashboard',
}

export enum UserRol{
    COMERCIO= 'comercio',
    COMERCIAL='comercial',
    ADMIN='admin'
}
