import {defineStore} from 'pinia'
import { Ref, ref} from "vue";
import {EnrollerModule, UserRol} from "@/common/Utils";
import {GetEnrollerConfiguration, Module} from "@/services/Http.service";
import keycloak from "@/security/keycloak-config";
import axios from "axios";

export const useConfigurationStore = defineStore('configuration', () => {
    const isInstance =ref(false);
    const enrollerModules = ref<string[]>([]);

    const initialState: GetEnrollerConfiguration = {
        name: 'undefined',
        tds_charge: '0',
        assets_url:'undefined',
        backend_url: 'localhost:3000',
        frontend_url: 'localhost:8080',
        branding_color:'undefined',
        modules: [{
            code: 'undefined',
            name: 'undefined',
            type: 'undefined',
            title: 'undefined',
            child: 'undefined',
            description: 'undefined'
        }]
    };

    const sideBar = ref({
        title: 'Plataforma',
        menu: [
            {title: 'Ventas', icon: 'mdi-list-box', name:  EnrollerModule.ORDEN_COMPRA, enrollerPermission: false, userPermission: false},
            {
                title: 'Ventas',
                icon: 'mdi-list-box',
                name: EnrollerModule.ORDEN_COMPRA_DASHBOARD,
                enrollerPermission: false,
                userPermission: false
            },
            {title: 'Link de pago', icon: 'mdi-qrcode', name: EnrollerModule.GATEWAY_LINK_PAGO, enrollerPermission: false, userPermission: false}
        ],
        submenu: [{
            title: 'Integraciones', icon: 'mdi-view-dashboard', enrollerPermission: false, userPermission: false,
            item: [
                {
                    title: 'Jumpseller',
                    icon: 'mdi-note-text',
                    name: EnrollerModule.GATEWAY_JUMPSELLER,
                    enrollerPermission: false,
                    userPermission: false
                },
                {
                    title: 'WooCommerce',
                    icon: 'mdi-note-text',
                    name: EnrollerModule.GATEWAY_WOOCOMMERCE,
                    enrollerPermission: false, userPermission: false
                },
                {
                    title: 'E-Commerce VTEX ',
                    icon: 'mdi-note-text',
                    name: EnrollerModule.GATEWAY_VTEX,
                    enrollerPermission: false, userPermission: false
                },
                {
                    title: 'Personalizadas',
                    icon: 'mdi-note-text',
                    name: EnrollerModule.GATEWAY_PERSONALIZADO,
                    enrollerPermission: false, userPermission: false
                }
            ]
        }, {
            title: 'Configuraciones', icon: 'mdi-view-dashboard', enrollerPermission: false, userPermission: false,
            item: [
                {title: 'Comercios', icon: 'mdi-store-cog', name: EnrollerModule.GESTION_COMERCIOS, enrollerPermission: false, userPermission: false},
                {
                    title: 'Puntos de ventas',
                    icon: 'mdi-home-group-plus',
                    name:  EnrollerModule.GESTION_PUNTOS_VENTA,
                    enrollerPermission: false, userPermission: false
                },
                {title: 'Credenciales (API)', icon: 'mdi-key-chain', name: EnrollerModule.GESTION_API_KEY, enrollerPermission: false, userPermission: false}
            ]
        }, {
            title: 'CRM', icon: 'mdi-view-dashboard', enrollerPermission: false, userPermission: false,
            item: [
                {title: 'Cuentas de comercios', icon: 'mdi-account-eye', name: EnrollerModule.CUENTAS_COMERCIOS, enrollerPermission: false, userPermission: false}
            ]
        }]
    });

    const enroller: Ref<GetEnrollerConfiguration> = ref<GetEnrollerConfiguration>(initialState);

    const getEnrollerConfiguration = async () => {
        if(isInstance.value)return;

        await axios.get(`/enroller/configuration`).then((response) => {
            const { data } = response;
                enroller.value = data;
                getEnrollerModules();
                setSidebar();
            })
            .catch((e) => {
                console.log(e)
            }).finally(() => {
                isInstance.value = true;
            });
    };

    const setSidebar = () => {
        getEnrollerPermission();
        getUserPermission();
    };

    const getEnrollerPermission = () => {
        /***********************************************************
                        PERMISOS NIVEL ENROLADOR MODULOS
         ************************************************************/
        sideBar.value.title = `Plataforma ${enroller.value.name}`;
        sideBar.value.menu.forEach((subItem: any) => {
            subItem.enrollerPermission = enrollerModules.value.includes(subItem.name);
        });

        sideBar.value.submenu.forEach((submenu: any) => {
            submenu.item.forEach((subItem: any) => {
                subItem.enrollerPermission = enrollerModules.value.includes(subItem.name);
            });
            submenu.enrollerPermission = submenu.item.some((module: any) => module.enrollerPermission);
        });
    }

    const getUserPermission = () => {
        /***********************************************************
                    PERMISOS NIVEL PERFIL USUARIO KEYCLOAK
         ************************************************************/
        sideBar.value.menu.forEach((subItem: any) => {
            subItem.userPermission = true
        });

        sideBar.value.submenu.forEach((submenu: any) => {
            switch (submenu.title) {
                case 'Integraciones':
                    submenu.userPermission = true;
                    submenu.item.forEach((subItem: any) => {
                        subItem.userPermission = true
                    });
                    break;
                case 'Configuraciones':
                    submenu.userPermission = true;
                    submenu.item.forEach((subItem: any) => {
                        subItem.userPermission = true;
                        if(subItem.name === EnrollerModule.GESTION_COMERCIOS) subItem.userPermission = keycloak.tokenParsed?.roles?.includes(UserRol.ADMIN);
                    });
                    break;
                case 'CRM':
                    submenu.userPermission = keycloak.tokenParsed?.roles?.includes(UserRol.ADMIN) || keycloak.tokenParsed?.roles?.includes(UserRol.COMERCIAL);
                    submenu.item.forEach((subItem: any) => {
                        subItem.userPermission = keycloak.tokenParsed?.roles?.includes(UserRol.ADMIN) || keycloak.tokenParsed?.roles?.includes(UserRol.COMERCIAL);
                    });
                    break;
                default:
                    submenu.userPermission = true;
            }
        });
    }

    const getEnrollerModules = () => {
        enrollerModules.value = enroller.value.modules.map((module: Module) => module.code);
    }

    return {enroller, sideBar, enrollerModules, getEnrollerConfiguration}
})
